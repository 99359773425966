import { mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { mapQueryData, mergeQueries, type QMergedResult } from "../../../utils/query/mergeQueries.ts";
import { vmQueryAtom, type VirtualMachineLoc } from "../../compute/vmQuery.ts";
import { allServicesQueryAtom, type ManagedServiceLoc } from "../../services/servicesQuery.ts";

const servicesByVmIdAtom = atom((get) =>
    mapQueryData(get(allServicesQueryAtom), (services) => {
        const result = new Map<string, ManagedServiceLoc>();

        // Flatten all VM resources from services
        for (const service of services?.values() ?? []) {
            for (const resource of service.resources) {
                if (resource.resource_type === "vm") {
                    result.set(resource.resource_id, service);
                }
            }
        }

        return result;
    }),
);

/** VM UUID -> Resource */
export const vpcVmTargetsAtom = atomFamily((location: string) =>
    atom((get): QMergedResult<Map<string, VirtualMachineLoc | ManagedServiceLoc>> => {
        const vms = get(vmQueryAtom(location));
        const services = get(servicesByVmIdAtom);
        return mergeQueries({ vms, services }, (maps) => mergeMaps(maps));
    }),
);
